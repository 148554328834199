<template>
    <!-- ======= Sidebar ======= -->
    <aside id="sidebar" class="sidebar">
        <ul class="sidebar-nav" id="sidebar-nav">
            <!-- <li class="nav-item"><router-link :class="{ 'nav-link' : true, collapsed : activeMonitoring }" :to="{ name : 'monitoring' }" @click="activateMonitoring"><i class="bi bi-tv"></i><span>Monitoring</span></router-link></li> -->
            <!-- <li class="nav-item"><router-link :class="{ 'nav-link' : true, collapsed : activeLock }" :to="{ name : 'lock' }" @click="activateLock"><i class="bi bi-toggles"></i><span>Buka / Tutup</span></router-link></li> -->
            <li class="nav-item">
                <router-link :class="{ 'nav-link' : true, collapsed : activePeserta }" :to="{ name : 'peserta' }" @click="activatePeserta">
                    <i class="fa fa-users"></i>
                    <span>Peserta</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link :class="{ 'nav-link' : true, collapsed : activeCFIT }" :to="{ name : 'cfit' }" @click="activateCFIT">
                    <i class="bi bi-ui-checks"></i>
                    <span>CFIT</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link :class="{ 'nav-link' : true, collapsed : activeKraepelin }" :to="{ name : 'kraepelin' }" @click="activateKraepelin">
                    <i class="bi bi-123"></i>
                    <span>Kraepelin</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link :class="{ 'nav-link' : true, collapsed : activePapikostick }" :to="{ name : 'papikostick' }" @click="activatePapikostick">
                    <i class="bi bi-spellcheck"></i>
                    <span>Papikostick</span>
                </router-link>
            </li>
            <!-- End Dashboard Nav -->
            <li class="nav-heading">Akun</li>
            <li class="nav-item">
                <router-link :class="{ 'nav-link' : true, collapsed : activeAdmin }" :to="{ name : 'admin' }" @click="activateAdmin">
                    <i class="bi bi-person"></i>
                    <span>Admin</span>
                </router-link>
            </li>
            <!-- End Profile Page Nav -->
            <!-- <li class="nav-item"><router-link :class="{ 'nav-link' : true, collapsed : activeKoorlap }" :to="{ name : 'koorlap' }" @click="activateKoorlap"><i class="bi bi-person-square"></i><span>Koor Lap</span></router-link></li> -->
        </ul>
    </aside>
    <!-- End Sidebar-->
</template>

<script>
    export default{
        data(){
            return {
                activeMonitoring 	: false,
                activeLock 			: true,
                activePeserta 		: true,
                activeCFIT 			: true,
                activeKraepelin 	: true,
                activePapikostick 	: true,
                activeAdmin 		: true,
                activeKoorlap 		: true,

                role 				: null,
                nama 				: this.$store.getters.getNama,

                lokasi_stat 		: false,
                lokasi 				: null
            }
        },
        methods : {
            activateMonitoring(){
                this.activeMonitoring 	= false
                this.activeLock 		= true
                this.activePeserta 		= true
                this.activeCFIT 		= true
                this.activeKraepelin 	= true
                this.activePapikostick 	= true
                this.activeAdmin 		= true
                this.activeKoorlap 		= true
            },
            activateLock(){
                this.activeMonitoring 	= true
                this.activeLock 		= false
                this.activePeserta 		= true
                this.activeCFIT 		= true
                this.activeKraepelin 	= true
                this.activePapikostick 	= true
                this.activeAdmin 		= true
                this.activeKoorlap 		= true
            },
            activatePeserta(){
                this.activeMonitoring 	= true
                this.activeLock 		= true
                this.activePeserta 		= false
                this.activeCFIT 		= true
                this.activeKraepelin 	= true
                this.activePapikostick 	= true
                this.activeAdmin 		= true
                this.activeKoorlap 		= true
            },
            activateCFIT(){
                this.activeMonitoring 	= true
                this.activeLock 		= true
                this.activePeserta 		= true
                this.activeCFIT 		= false
                this.activeKraepelin 	= true
                this.activePapikostick 	= true
                this.activeAdmin 		= true
                this.activeKoorlap 		= true
            },
            activateKraepelin(){
                this.activeMonitoring 	= true
                this.activeLock 		= true
                this.activePeserta 		= true
                this.activeCFIT 		= true
                this.activeKraepelin 	= false
                this.activePapikostick 	= true
                this.activeAdmin 		= true
                this.activeKoorlap 		= true
            },
            activatePapikostick(){
                this.activeMonitoring 	= true
                this.activeLock 		= true
                this.activePeserta 		= true
                this.activeCFIT 		= true
                this.activeKraepelin 	= true
                this.activePapikostick 	= false
                this.activeAdmin 		= true
                this.activeKoorlap 		= true
            },
            activateAdmin(){
                this.activeMonitoring 	= true
                this.activeLock 		= true
                this.activePeserta 		= true
                this.activeCFIT 		= true
                this.activeKraepelin 	= true
                this.activePapikostick 	= true
                this.activeAdmin 		= false
                this.activeKoorlap 		= true
            },
            activateKoorlap(){
                this.activeMonitoring 	= true
                this.activeLock 		= true
                this.activePeserta 		= true
                this.activeCFIT 		= true
                this.activeKraepelin 	= true
                this.activePapikostick 	= true
                this.activeAdmin 		= true
                this.activeKoorlap 		= false
            }
        },
        async mounted(){
            $.LoadingOverlay("show", { image : "", fontawesome : "fa fa-cog fa-spin" })
            console.log('STORE TOKEN :',this.$store.getters.getToken)
            console.log('STORE ID :',this.$store.getters.getId)
            console.log('STORE ROLE :',this.$store.getters.getRole)
            console.log('STORE NAMA :',this.$store.getters.getNama)
            console.log('STORE LOKASI :',this.$store.getters.getLokasi)
            let lokasi = this.$store.getters.getLokasi
            if(lokasi){
                this.lokasi_stat 	= true
                this.lokasi 		= lokasi
            }
            let role = this.$store.getters.getRole
            if(role){
                this.role 			= 'KoorLap'
            } else {
                this.role 			= 'Admin'
            }
            $.LoadingOverlay("hide", true)
        }
    }
</script>
<template>
    <main>
        <div class="container">
            <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                            <div class="d-flex justify-content-center py-4">
                                <a href="index.html" class="logo d-flex align-items-center w-auto">
                                    <img src="/img/sukun-only.png" alt="">
                                    <span class="d-none d-lg-block">Portal Admin Psikotes</span>
                                </a>
                            </div>
                            <!-- End Logo -->
                            <div class="card mb-3">
                                <div class="card-body">
                                    <div class="pt-4 pb-2">
                                        <h5 class="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                                    </div>
                                    <form class="row g-3 needs-validation" @submit.prevent="onSubmit">
                                        <div class="col-12">
                                            <label for="yourUsername" class="form-label">Username</label>
                                            <div class="input-group has-validation">
                                                <input type="text" v-model="username" class="form-control" id="yourUsername">
                                                <div class="invalid-feedback" :style="{ display : username_validate_display }">{{ username_validate_msg }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <label for="yourPassword" class="form-label">Password</label>
                                            <input type="password" v-model="password" class="form-control" id="yourPassword">
                                            <div class="invalid-feedback" :style="{ display : password_validate_display }">{{ password_validate_msg }}</div>
                                        </div>
                                        <div class="col-12">
                                            <button class="btn btn-primary w-100" type="submit">Login</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="credits">
                                <!-- All the links in the footer should remain intact. -->
                                <!-- You can delete the links only if you purchased the pro version. -->
                                <!-- Licensing information: https://bootstrapmade.com/license/ -->
                                <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/ --> <!-- Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> --> Powered by HRD PT. SWI &amp; CV.MIB Labs
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </main>
</template>
    
<script>
    import axios from 'axios'
    export default{
        data(){
            return {
                holder 						: null,
    
                username 					: null,
                username_validate_display 	: 'none',
                username_validate_msg 		: '',
    
                password 					: null,
                password_validate_display 	: 'none',
                password_validate_msg 		: ''
            }
        },
        methods : {
            async onSubmit(){
                var username_par
                if(this.username==this.holder){
                    this.username_validate_display 	= 'block'
                    this.username_validate_msg 		= 'Username Harus Diisi'
    
                    username_par = false
                } else {
                    this.username_validate_display 	= 'none'
                    this.username_validate_msg 		= ''
    
                    username_par = true
                }
    
                var password_par
                if(this.password==this.holder){
                    this.password_validate_display 	= 'block'
                    this.password_validate_msg 		= 'Password Harus Diisi'
    
                    password_par = false
                } else {
                    this.password_validate_display 	= 'none'
                    this.password_validate_msg 		= ''
    
                    password_par = true
                }
    
                if(username_par && password_par){
                    try {
                        console.log('REQ',{ method : 'post', url : this.$store.getters.getServerURL+'admin/login', data : { username : this.username, password : this.password } })
                        var response = await axios({ method : 'post', url : this.$store.getters.getServerURL+'admin/login', data : { username : this.username, password : this.password } })
                        console.log('RES',response)
                        if(response.data.error_code == 1){
                            this.password_validate_display 	= 'block'
                            this.password_validate_msg 		= response.data.error_desc
                        } else if(response.data.error_code == 2){
                            this.username_validate_display 	= 'block'
                            this.username_validate_msg 		= response.data.error_desc
                        } else {
                            let data = response.data.data
                            this.$store.dispatch('setToken',data.token)
                            this.$store.dispatch('setId',data.ID)
                            this.$store.dispatch('setRole',data.role)
                            this.$store.dispatch('setNama',data.nama)
                            this.$store.dispatch('setLokasi',data.lokasi)
                            if(data.role){
                                this.$router.push({ name : 'peserta' })
                            } else {
                                this.$router.push({ name : 'peserta' })
                            }
                        }
                    } catch(error) {
                        console.error(error)
                    }
                }
            }
        }
    }
</script>
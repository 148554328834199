<template>
    <!-- ======= Header ======= -->
    <header id="header" class="header fixed-top d-flex align-items-center">
        <div class="d-flex align-items-center justify-content-between">
            <router-link :to="{ name : 'peserta' }" class="logo d-flex align-items-center">
                <img src="/img/sukun-only.png" alt="">
                <span class="d-none d-lg-block">{{ role }}</span>
            </router-link>
            <i class="bi bi-list toggle-sidebar-btn"></i>
        </div>
        <!-- End Logo -->
        <div class="search-bar">
            <form class="search-form d-flex align-items-center">
                <input type="text" title="Enter search keyword" v-if="lokasi_stat" v-model="lokasi" readonly>
            </form>
        </div>
        <!-- End Search Bar -->
        <nav class="header-nav ms-auto">
            <ul class="d-flex align-items-center">
                <!-- End Messages Nav -->
                <li class="nav-item dropdown pe-3">
                    <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                        <!-- <img src="./img/profile-img.jpg" alt="Profile" class="rounded-circle"> -->
                        <i class="fa fa-user-circle" style="height:100%"></i>
                        <span class="d-none d-md-block dropdown-toggle ps-2">{{ nama }}</span>
                    </a>
                    <!-- End Profile Iamge Icon -->
                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                        <li class="dropdown-header">
                            <h6>{{ nama }}</h6>
                            <span>{{ role }}</span>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li>
                            <router-link class="dropdown-item d-flex align-items-center" :to="{ name : 'reset_password' }">
                                <i class="bi bi-gear"></i>
                                <span>Reset Password</span>
                            </router-link>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li>
                            <a class="dropdown-item d-flex align-items-center" href="#" @click="logout">
                                <i class="bi bi-box-arrow-right"></i>
                                <span>Sign Out</span>
                            </a>
                        </li>
                    </ul>
                    <!-- End Profile Dropdown Items -->
                </li>
                <!-- End Profile Nav -->
            </ul>
        </nav>
        <!-- End Icons Navigation -->
    </header>
    <!-- End Header -->
</template>

<script>
    export default{
        data(){
            return {
                role    : null,
                nama    : this.$store.getters.getNama,
            }
        },
        methods : {
            logout(){
                window.localStorage.removeItem('vuex');
                this.$router.go(0)
                // this.$router.push({ name : 'login' })
            },
        },
        mounted(){
            let role = this.$store.getters.getRole
            if(role){
                this.role 			= 'KoorLap'
            } else {
                this.role 			= 'Admin'
            }
        }
    }
</script>